var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-6" },
    [
      _c(
        "v-sheet",
        {
          staticClass: "mb-7",
          attrs: { color: "transparent", "max-width": "550px" },
        },
        [_vm._v(" " + _vm._s(_vm.$t("interface.export.desc")) + " ")]
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-center mt-5" },
        [
          _c("mew-button", {
            attrs: { title: "Export", "btn-size": "xlarge" },
            nativeOn: {
              click: function ($event) {
                return _vm.exportConfig.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }